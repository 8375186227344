import React from "react";
import styled from "styled-components";
import logoAliado from "../../images/logo-hotel-cotopaxi.png"; // Asegúrate de tener el logo aquí
import aliado1 from "../../images/aliados1.jpg";
import aliado2 from "../../images/aliados2.jpg";
import aliado3 from "../../images/aliados3.jpg";
import aliado4 from "../../images/aliados4.jpg";
import calendarios from "../../images/calendario.png";
import calidad from "../../images/serviciosCalid.png";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 80px 30px;
  z-index: 1;

  @media (max-width: 960px) {
    padding: 66px 16px;
  }

  @media (max-width: 640px) {
    padding: 32px 16px;
  }
`;

const LogoImage = styled.img`
  width: 250px;
  margin-bottom: 20px;
`;
const ImageEventos = styled.img`
  width: 100%; /* Asegura que ocupe todo el ancho disponible en el contenedor */
  max-width: 300px; /* Opcional: limita el tamaño máximo de la imagen */
  height: auto;
  object-fit: cover;
  border-radius: 8px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espacio entre las imágenes */
  margin-top: 20px;

  @media (max-width: 768px) {
    /* Ajusta el tamaño de pantalla según tus necesidades */
    display: none;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left;
`;
/**nuevos */
const ContainerCard = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 40px auto;
  font-family: Arial, sans-serif;
`;
const ListingDetails = styled.div`
  width: 65%;
  padding-right: 20px;

  @media (max-width: 768px) {
    width: 100%; /* Ocupa el ancho completo cuando la imagen se oculta */
    padding-right: 0; /* Opcional: elimina el padding derecho en pantallas pequeñas */
  }
`;

const Subtitle = styled.p`
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
`;

const Divider = styled.hr`
  color: white;
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
`;

const FeatureSection = styled.div`
  margin-bottom: 20px;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const FeatureIcon = styled.img`
  color: white;
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const FeatureText = styled.p`
  font-size: 14px;
  color: white;
`;

const Description = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  color: white;
`;

const ReserveButton = styled.a`
  background-color: #ff385c;
  color: white;
  border: none;
  padding: 10px 20px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e3335f; /* Slightly darker shade for hover effect */
  }
`;

const SmallText = styled.p`
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  color: #717171;
`;

const ServiceList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
`;

const ServiceItem = styled.div`
  color: white;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
`;

const ServiceIcon = styled.span`
  font-size: 20px;
  margin-right: 8px;
  color: white;
`;
const Section = styled.div`
  margin-bottom: 20px;
`;
const ElegantSection = styled.section`
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;
const List = styled.ul`
  list-style-type: disc;
  margin: 10px 0;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
`;
const SmallImageGrid = styled.div`
  display: none; /* Ocultar por defecto */
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
  }
`;

const SmallImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

function Aliados() {
  const phoneNumber = "+593997924630";
  return (
    <Container>
      <ContainerCard>
        <ListingDetails>
          <LogoImage src={logoAliado} alt="Logo" />
          <Title>
            Balcon al Cotopaxi Hosteria marca aliada a Casa D' Bruno:
          </Title>
          <Divider />
          <FeatureSection>
            <Feature>
              <FeatureIcon src={calendarios} alt="Icon" />
              <FeatureText>Ideal para Eventos - Reuniones</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon src={calidad} alt="Icon" />
              <FeatureText>
                Cuenta con · Camping · Restaurante · Senderos Ecológicos ·
                Cabalgatas
              </FeatureText>
            </Feature>
          </FeatureSection>
          <Divider />
          <Description>
            Nos complace anunciar la alianza entre{" "}
            <strong>La Hostería Balcón al Cotopaxi</strong> y
            <strong> Casa D' Bruno</strong>, que busca potenciar lo mejor del
            turismo, la aventura y la gastronomía. Juntos, ofrecemos
            experiencias únicas que conectan la naturaleza del canton Rumiñahui
            con la hospitalidad y el sabor que caracteriza a ambos destinos.
          </Description>
          <Divider />
          <Section>
            <Subtitle>
              Colaboración entre Balcón al Cotopaxi y Casa D' Bruno
            </Subtitle>
            <Description>
              A través de esta colaboración, te invitamos a disfrutar de un
              emocionante "Full Day" que incluye una visita a la Hostería Balcón
              al Cotopaxi, donde podrás explorar sus impresionantes paisajes,
              disfrutar de un desayuno a la carta y relajarte en su entorno
              natural, para luego culminar con un delicioso almuerzo parrillero
              en Casa D' Bruno.
            </Description>
            <Description>
              La alianza también busca fomentar el turismo responsable y
              promover la riqueza cultural y natural de la región, ofreciendo
              servicios exclusivos bajo reservación que aseguran una experiencia
              personalizada y memorable.
            </Description>
          </Section>
          <Section>
            <Description>
              <strong style={{ color: "white" }}>Reservaciones:</strong>
              <b style={{ color: "white" }}>
                {" "}
                Requiere previa reservación para garantizar la mejor atención.{" "}
              </b>
            </Description>
          </Section>
          <Divider />
          <SmallImageGrid>
            <SmallImage src={aliado1} alt="Cocina 1" />
            <SmallImage src={aliado2} alt="Cocina 2" />
            <SmallImage src={aliado3} alt="Cocina 3" />
            <SmallImage src={aliado4} alt="Cocina 4" />
          </SmallImageGrid>
          <ElegantSection>
            <Subtitle>Full Day</Subtitle>
            <Description>
              Visita a la Hostería Balcón al Cotopaxi en cuadrón.
            </Description>
            <List>
              <ListItem>Distancia: Aproximada 40 km.</ListItem>
              <ListItem>Duración: Aproximada 7 horas</ListItem>
            </List>
            <Description>
              Casa d´ Bruno te invita a disfrutar de nuestras emocionantes rutas
              de cuadrones y motos, recorriendo las parroquias rurales de
              Cotogcho, Rumipamba y la parroquia urbana de Sangolquí del Cantón
              Rumiñahui.
            </Description>
            <ListItem>💵 Precio:</ListItem>
            <ul>
              <ListItem>👤 $75 por persona.</ListItem>
              <ListItem>👥 $35 segunda persona en el mismo cuadrón.</ListItem>
            </ul>
            <ServiceList>
              <ServiceItem>
                <ServiceIcon>🏔️</ServiceIcon>
                Vista panorámica al volcán Cotopaxi
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🌳</ServiceIcon>
                Acceso a áreas verdes y naturales
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🍽️</ServiceIcon>
                Desayuno incluido en la hostería
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🚗</ServiceIcon>
                Parqueadero gratuito
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>📸</ServiceIcon>
                Fotos y videos durante la experiencia
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🔥</ServiceIcon>
                Almuerzo parrillero en Casa d´ Bruno
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🚶</ServiceIcon>
                Caminatas guiadas opcionales
              </ServiceItem>
            </ServiceList>
            <ReserveButton
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                "Mas informacion sobre Cocina colaborativa"
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Reservar
            </ReserveButton>
            <SmallText>Contactate con nosotros</SmallText>
          </ElegantSection>
        </ListingDetails>
        <ImageContainer>
          <ImageEventos src={aliado1} alt="Cocina 1" />
          <ImageEventos src={aliado2} alt="Cocina 2" />
          <ImageEventos src={aliado3} alt="Cocina 3" />
          <ImageEventos src={aliado4} alt="Cocina 4" />
        </ImageContainer>
      </ContainerCard>
    </Container>
  );
}

export default Aliados;
