import { BrowserRouter, Routes, Route } from "react-router-dom";
import Servicios from "./components/sections/Servicios";
import Cocina from "./components/sections/Cocina";
import Diversion from "./components/sections/Diversion";
import Navbar from "./components/Navbar";
import Experience from "./components/sections/Experience";
import Hero from "./components/sections/Hero";
import Contact from "./components/sections/Contact";
import Footer from "./components/sections/Footer";
import StartCanvas from "./components/canvas/Stars";
import styled, { ThemeProvider } from "styled-components";
import { darkTheme } from "./utils/Themes";
import PhotoCollage3D from "./components/sections/albunes/PhotoCollage3D";
import PhotoCollage3DDiversion from "./components/sections/albunes/PhotoCollage3DDiversion";
import PhotoCollage3DCocina from "./components/sections/albunes/PhotoCollage3DCocina";
import ScrollToTop from "./components/ScrollToTop";
import Aliados from "./components/sections/Aliados";

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
  position: relative;
`;

const Wrapper = styled.div`
  padding-bottom: 100px;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <ScrollToTop /> {/* Añadir el componente aquí */}
        <Navbar />
        <Body>
          <StartCanvas />
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  <Hero />
                  <Wrapper>
                    <Experience />
                  </Wrapper>
                  <Wrapper>
                    <Contact />
                  </Wrapper>
                  <Footer />
                </div>
              }
            />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/cocina" element={<Cocina />} />
            <Route path="/diversion" element={<Diversion />} />
            <Route path="/collage" element={<PhotoCollage3D />} />
            <Route path="/aliados" element={<Aliados />} />
            <Route
              path="/collageDiversion"
              element={<PhotoCollage3DDiversion />}
            />
            <Route path="/collageCocina" element={<PhotoCollage3DCocina />} />
          </Routes>
        </Body>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
