import React, { Suspense, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// Importing images
import foto1 from "../../images/diversion1.jpeg";
import foto2 from "../../images/diversion2.jpeg";
import foto3 from "../../images/diversion3.jpeg";
import foto4 from "../../images/diversion4.jpeg";
import foto5 from "../../images/diversion5.jpeg";
import eventos from "../../images/Diversionpersonaje.png";
import javier from "../../images/javier.png";
import personas from "../../images/personas.png";
import calendarios from "../../images/calendario.png";
import calidad from "../../images/serviciosCalid.png";
import { Modal } from "@mui/material";
import alquilarIcon from "./../../images/alquilar.png";
import camareroIcon from "./../../images/camarero.png";
import posiciom from "./../../images/marcadorPosicion.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 80px 30px;
  z-index: 1;

  @media (max-width: 960px) {
    padding: 66px 16px;
  }

  @media (max-width: 640px) {
    padding: 32px 16px;
  }
`;

/**hola */
const ContainerImagen = styled.div`
  max-width: 1100px;
  margin: 20px auto;
  padding: 20px;
`;
const Title = styled.h1`
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left;
`;
const TitleCard = styled.h1`
  font-size: 24px;
  color: black;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const SmallImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;
const ImageEventos = styled.img`
  width: 40%;
  height: 40%;
  object-fit: cover;
  border-radius: 8px;

  @media (max-width: 768px) {
    /* Ajusta el tamaño de pantalla según tus necesidades */
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledLink = styled(Link)`
  padding: 12px 24px;
  background-color: white;
  color: black;
  text-decoration: none;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #d1d1d1;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    background-color: #f0f0f0;
  }

  &:before {
    content: "•";
    margin-right: 8px;
  }
`;
/**nuevos */
const ContainerCard = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 40px auto;
  font-family: Arial, sans-serif;
`;
const ListingDetails = styled.div`
  width: 65%;
  padding-right: 20px;

  @media (max-width: 768px) {
    width: 100%; /* Ocupa el ancho completo cuando la imagen se oculta */
    padding-right: 0; /* Opcional: elimina el padding derecho en pantallas pequeñas */
  }
`;
const PriceContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  gap: 20px; /* Adjust gap as needed */
`;
const PriceSection = styled.div`
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Subtitle = styled.p`
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
`;

const Divider = styled.hr`
  color: white;
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
`;

const HostSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const HostImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
`;

const HostInfo = styled.div`
  color: white;
  font-size: 14px;
`;

const FeatureSection = styled.div`
  margin-bottom: 20px;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const FeatureIcon = styled.img`
  color: white;
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const FeatureText = styled.p`
  font-size: 14px;
  color: white;
`;

const Description = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  color: white;
`;

const GuestSelector = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
`;

const TotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
`;

const ReserveButton = styled.a`
  background-color: #ff385c;
  color: white;
  border: none;
  padding: 10px 20px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e3335f; /* Slightly darker shade for hover effect */
  }
`;

const SmallText = styled.p`
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  color: #717171;
`;
const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ServiceList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
`;

const ServiceItem = styled.div`
  color: white;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
`;

const ServiceIcon = styled.span`
  font-size: 20px;
  margin-right: 8px;
  color: white;
`;

const ShowMoreButton = styled.button`
  padding: 12px 24px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const DescriptionSection = styled.div`
  flex: 1;
  padding-right: 20px;

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    margin: 0; /* Elimina el margen para evitar desalineación */
  }

  ul {
    padding-left: 20px; /* Asegura que los puntos de la lista estén dentro del Card */
    margin: 0; /* Elimina el margen para evitar desbordamientos */
  }

  li {
    margin-bottom: 8px; /* Añade espacio entre elementos de la lista */
    word-break: break-word; /* Asegura que el texto largo se ajuste */
  }

  strong {
    display: block;
    font-size: 20px;
    margin-top: 16px;
    color: #f4ac1c;
  }
`;
const TitleSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex: 1;

  h2 {
    font-size: 24px;
    margin-left: 10px;
    color: #333;
  }
`;
const Img = styled.img`
  width: 10%;
  height: auto;
  max-width: 50px;
  max-height: 50px;

  @media (max-width: 640px) {
    max-width: 100px;
    max-height: 100px;
  }
`;
const ScheduleSection = styled.div`
  flex: 1;
  padding-left: 20px;

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    margin: 0; /* Elimina el margen para evitar desalineación */
  }

  ul {
    padding-left: 20px; /* Asegura que los puntos de la lista estén dentro del Card */
    margin: 0; /* Elimina el margen para evitar desbordamientos */
  }

  li {
    margin-bottom: 8px; /* Añade espacio entre elementos de la lista */
    word-break: break-word; /* Asegura que el texto largo se ajuste */
  }

  strong {
    display: block;
    font-size: 20px;
    margin-top: 16px;
    color: #f4ac1c;
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  max-width: 60vw;
  max-height: 90vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 12px;
  margin: auto; /* Centra horizontalmente */
  position: relative;
  top: 50%;
  transform: translateY(-50%); /* Centra verticalmente */
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 1200px;
  max-height: calc(
    90vh - 40px
  ); /* Ajusta la altura máxima para que se mantenga dentro del modal */
  overflow-y: auto; /* Habilita el desplazamiento vertical dentro del card */
  margin: 20px auto; /* Ajusta el margen para centrar el card */
  color: black;
  position: relative;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 20px;
  }
`;
const Button = styled.a`
  display: inline-block;
  margin-top: 10px; /* Alinea el botón al final de la sección */
  padding: 10px 20px;
  background-color: #f4ac1c;
  color: #fff;
  text-decoration: none; /* Evita el subrayado */
  text-align: center;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    background-color: #d69317; /* Color de fondo al hacer hover */
  }
`;
// Estilos existentes
const Table = styled.table`
  border-collapse: collapse;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  text-align: center;
  overflow-x: auto; /* Permite el desplazamiento horizontal en pantallas pequeñas */
  display: block; /* Hace que la tabla se comporte como un bloque para el desplazamiento horizontal */
`;

const Th = styled.th`
  background-color: #f4ac1c;
  color: white;
  padding: 8px;
  font-size: 14px; /* Tamaño de fuente ajustado */
`;

const Td = styled.td`
  border: 1px solid #dddddd;
  padding: 8px;
  font-size: 14px; /* Tamaño de fuente ajustado */
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
// Lista de itinerario
const ItineraryList = styled.ul`
  list-style-type: none; /* Elimina los puntos de la lista */
  padding: 0;
  margin: 0;
`;

const ItineraryItem = styled.li`
  padding: 8px 0; /* Espaciado vertical */
  border-bottom: 1px solid #ddd; /* Línea divisoria */
  font-size: 14px; /* Tamaño de fuente */
  color: #555;
`;
// Definimos las animaciones usando keyframes de styled-components
const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const moveDot = keyframes`
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
`;

// Estilos para el contenedor del SVG
const SvgContainer = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const StyledSvg = styled.svg`
  width: 200%; /* Ajusta el tamaño de la ruta */
  height: auto;

  .c-loader__circuit__path {
    animation: ${dash} 5s linear infinite;
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    stroke: #ededed;
  }

  .c-loader__circuit__dot {
    animation: ${moveDot} 5s linear infinite;
    offset-path: path(
      "M13.4,19.5c0,0,20.2-18.4,23.6-7.6C38.7,17.3,226.9,141,230.9,144s24.5,20.5,7.5,38s-82.9,69.6-85.4,71.6s-7.1,10.4-13.6,14.9s-24.4,2.1-26.9-1.9c0,0,2.5-47,28.9-51.6c10.3-1.8-4.5-19.5-7-28.5s-21.5-7-26-25s-5.5-27-14.5-36.5s-62-44.5-67-50s10-10-0.5-24.5S0.5,38.1,13.4,19.5z"
    );
    offset-rotate: 0deg;
  }

  .js-circle:nth-child(1) {
    animation: ${moveDot} 5s linear infinite;
  }

  .js-circle:nth-child(2) {
    animation: ${moveDot} 5s linear infinite;
    animation-delay: 2.5s; /* Añade un retraso para el segundo punto */
  }
`;

const ElegantSection = styled.section`
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;
const List = styled.ul`
  list-style-type: disc;
  margin: 10px 0;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
`;
// Configuración del icono del marcador
const customIcon = new L.Icon({
  iconUrl: posiciom,
  iconSize: [38, 38],
  iconAnchor: [22, 38],
  popupAnchor: [-3, -76],
});

// Estilos para el contenedor del mapa
const MapWrapper = styled.div`
  width: 50%;
  height: 400px; /* Ajusta la altura según tus necesidades */
  margin-bottom: 10px;
`;
function Diversion() {
  const phoneNumber = "+593997924630";

  const handleClick = () => {
    console.log("Link clicked");
  };
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const position = [-0.3525835274196284, -78.44864845917898]; // Coordenadas del centro del mapa
  // Función para abrir Google Maps con la dirección
  const handleDirectionsClick = () => {
    const destination = `${position[0]},${position[1]}`;
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${destination}`,
      "_blank"
    );
  };
  return (
    <Container>
      <ContainerImagen>
        <Title>CASA D´BRUNO - DIVERSIÓN</Title>
        <GridContainer>
          <MainImage src={foto1} alt="Vista al mar" />
          <SmallImageGrid>
            <Image src={foto2} alt="Cocina 1" />
            <Image src={foto3} alt="Cocina 2" />
            <Image src={foto4} alt="Cocina 3" />
            <Image src={foto5} alt="Cocina 4" />
          </SmallImageGrid>
        </GridContainer>
        <ButtonContainer>
          <StyledLink to="/collageDiversion" onClick={handleClick}>
            Mostrar todas las fotos
          </StyledLink>
        </ButtonContainer>
      </ContainerImagen>
      <ContainerCard>
        <ListingDetails>
          <Title>
            Casa d´ Bruno te invita a disfrutar de nuestras emocionantes rutas:
          </Title>
          <Subtitle>
            Alquiler de equipamiento deportivo: cuadrones, motos y bicicletas
            bajo el sistema de rutas.
          </Subtitle>
          <Divider />
          <HostSection>
            <HostImage src={javier} alt="Host" />
            <HostInfo>
              <p>Anfitrión: Javier Dominguez </p>
            </HostInfo>
          </HostSection>
          <FeatureSection>
            <Feature>
              <FeatureIcon src={calendarios} alt="Icon" />
              <FeatureText>Ideal para Eventos</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon src={personas} alt="Icon" />
              <FeatureText>Capacidad Hasta 100 personas</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon src={calidad} alt="Icon" />
              <FeatureText>Cuenta con dos tipos de servicio</FeatureText>
            </Feature>
          </FeatureSection>
          <Divider />
          <Description>
            Casa D´ Bruno te invita a disfrutar de nuestras emocionantes rutas
            de cuadrones y motos, recorriendo las parroquias rurales de
            Cotogcho, Rumipamba y la parroquia urbana de Sangolquí del Cantón
            Rumiñahui. Al recorrer el sector rural del cantón se tiene un
            paisaje único donde se puede apreciar una variada gama de vistas que
            incluyen montañas imponentes, valles agrícolas, bosques exuberantes,
            y pintorescos paisajes rurales. Es una ruta que no solo desafía
            físicamente, sino que también recompensa con vistas espectaculares y
            una inmersión en la belleza natural del cantón Rumiñahui.
          </Description>
          <Divider />
          <ElegantSection>
            {/* Nueva sección Full Day */}
            <Title>Full Day</Title>
            <Subtitle>
              Visita a la Hostería Balcón al Cotopaxi en cuadrón.
            </Subtitle>
            <List>
              <ListItem>🚗 Distancia: Aproximada 40 km.</ListItem>
              <ListItem>⏱️ Duración: Aproximada 7 horas</ListItem>
              <ListItem>🗺️ Itinerario:</ListItem>
              <ul>
                <ListItem>🚙 Salida a las 7:00 am de Casa d´Bruno.</ListItem>
                <ListItem>
                  🏞️ Llegada a la Hostería Balcón al Cotopaxi 9:00 am.
                </ListItem>
                <ListItem>⛺ Visita hostería hasta 11:00 am.</ListItem>
                <ListItem>🔙 Retorno a Casa d´Bruno 13:00 pm.</ListItem>
              </ul>
              <ListItem>🎒 ¿Qué incluye?</ListItem>
              <ul>
                <ListItem>🏍️ Guía experto en la ruta (moto enduro)</ListItem>
                <ListItem>🏨 Ingreso a la hostería.</ListItem>
                <ListItem>
                  🥐 Desayuno a elegir en la hostería según carta.
                </ListItem>
                <ListItem>🍖 Almuerzo parrillero en Casa d´Bruno.</ListItem>
                <ListItem>💧 Bebida hidratante.</ListItem>
                <ListItem>📸 Fotos y videos durante el recorrido.</ListItem>
                <ListItem>🛡️ Equipamiento adecuado para cuadrón.</ListItem>
                <ListItem>🅿️ Parqueadero seguro para tu auto.</ListItem>
              </ul>
              <ListItem>💵 Precio:</ListItem>
              <ul>
                <ListItem>👤 $75 por persona.</ListItem>
                <ListItem>👥 $35 segunda persona en el mismo cuadrón.</ListItem>
              </ul>
              <ListItem>🕒 Horario:</ListItem>
              <ul>
                <ListItem>📅 Sábado y domingo bajo reservación.</ListItem>
              </ul>
            </List>
            <ReserveButton
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                "Mas informacion sobre el Plan Full Day"
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Reservar Full Day
            </ReserveButton>
            <SmallText>Contactate con nosotros para más detalles.</SmallText>
          </ElegantSection>
        </ListingDetails>
        <ImageEventos src={eventos} alt="Cocina 1" />
      </ContainerCard>
      <Title>PAQUETES FULL DAY CASA D´ BRUNO</Title>
      <SvgContainer>
        <StyledSvg
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 283.6 305.5"
          xmlSpace="preserve"
          className="c-loader__circuit"
        >
          <path
            fill="none"
            stroke="#ededed"
            strokeWidth="7"
            strokeMiterlimit="10"
            d="M13.4,19.5c0,0,20.2-18.4,23.6-7.6C38.7,17.3,226.9,141,230.9,144s24.5,20.5,7.5,38s-82.9,69.6-85.4,71.6s-7.1,10.4-13.6,14.9s-24.4,2.1-26.9-1.9c0,0,2.5-47,28.9-51.6c10.3-1.8-4.5-19.5-7-28.5s-21.5-7-26-25s-5.5-27-14.5-36.5s-62-44.5-67-50s10-10-0.5-24.5S0.5,38.1,13.4,19.5z"
            className="c-loader__circuit__path js-path"
          ></path>
          <g className="c-loader__circuit__dot js-circle">
            <circle fill="#fff" cx="0" cy="0" r="3"></circle>
          </g>
          <g className="c-loader__circuit__dot js-circle">
            <circle fill="#fff" cx="0" cy="0" r="3"></circle>
          </g>
        </StyledSvg>
      </SvgContainer>
      <Title>Costos y Planes de Casa D' Bruno</Title>
      <PriceContainer>
        <PriceSection>
          <TitleCard>Itinerario</TitleCard>

          <ItineraryList>
            <ItineraryItem>Salida a las 7:00 am de Casa d´Bruno.</ItineraryItem>
            <ItineraryItem>
              Llegada a la Hostería Balcón al Cotopaxi 9:00 am.
            </ItineraryItem>
            <ItineraryItem>Visita hostería hasta 11:00 am.</ItineraryItem>
            <ItineraryItem>Retorno a Casa d´Bruno 13:00 pm.</ItineraryItem>
          </ItineraryList>

          <TitleCard>Precio</TitleCard>

          <Table>
            <thead>
              <Tr>
                <Th>DESCRIPCIÓN</Th>
                <Th>PRECIO</Th>
              </Tr>
            </thead>
            <tbody>
              <Tr>
                <Td>Por persona</Td>
                <Td>$75.00</Td>
              </Tr>
              <Tr>
                <Td>Segunda persona en el mismo cuadrón</Td>
                <Td>$35.00</Td>
              </Tr>
            </tbody>
          </Table>
          <ReserveButton
            href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
              "Mas informacion sobre los cuadrones"
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Reservar
          </ReserveButton>
          <SmallText>Contactate con nosotros</SmallText>

          <TotalPrice>
            <span>Total</span>
            <span>No disponible</span>
          </TotalPrice>
        </PriceSection>
      </PriceContainer>
      {/* Agregar el mapa */}
      <Title>Nuestra ubicacion Casa D' Bruno</Title>
      <MapWrapper>
        <MapContainer
          center={position}
          zoom={15}
          scrollWheelZoom={false}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} icon={customIcon}>
            <Popup>
              Aquí es donde te quedarás.
              <br />
              <button onClick={handleDirectionsClick}>Cómo llegar</button>
            </Popup>
          </Marker>
        </MapContainer>
      </MapWrapper>
      <Button onClick={handleDirectionsClick}>Cómo llegar</Button>
    </Container>
  );
}

export default Diversion;
