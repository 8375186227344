import React, { Suspense, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// Importing images
import foto1 from "../../images/cocina1.jpeg";
import foto2 from "../../images/cocina2.jpeg";
import foto3 from "../../images/cocina3.jpeg";
import foto4 from "../../images/cocina4.jpeg";
import foto5 from "../../images/cocina5.jpeg";
import cocina from "../../images/cocina.png";
import javier from "../../images/javier.png";
import personas from "../../images/personas.png";
import calendarios from "../../images/calendario.png";
import calidad from "../../images/serviciosCalid.png";
import posiciom from "./../../images/marcadorPosicion.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 80px 30px;
  z-index: 1;

  @media (max-width: 960px) {
    padding: 66px 16px;
  }

  @media (max-width: 640px) {
    padding: 32px 16px;
  }
`;

/**hola */
const ContainerImagen = styled.div`
  max-width: 1100px;
  margin: 20px auto;
  padding: 20px;
`;
const Title = styled.h1`
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left;
`;
const TitleCard = styled.h1`
  font-size: 24px;
  color: black;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const SmallImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;
const ImageEventos = styled.img`
  width: 40%;
  height: 40%;
  object-fit: cover;
  border-radius: 8px;

  @media (max-width: 768px) {
    /* Ajusta el tamaño de pantalla según tus necesidades */
    display: none;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledLink = styled(Link)`
  padding: 12px 24px;
  background-color: white;
  color: black;
  text-decoration: none;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #d1d1d1;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    background-color: #f0f0f0;
  }

  &:before {
    content: "•";
    margin-right: 8px;
  }
`;
/**nuevos */
const ContainerCard = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 40px auto;
  font-family: Arial, sans-serif;
`;
const ListingDetails = styled.div`
  width: 65%;
  padding-right: 20px;

  @media (max-width: 768px) {
    width: 100%; /* Ocupa el ancho completo cuando la imagen se oculta */
    padding-right: 0; /* Opcional: elimina el padding derecho en pantallas pequeñas */
  }
`;
const PriceContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  gap: 20px; /* Adjust gap as needed */
`;
const PriceSection = styled.div`
  width: 40%;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Subtitle = styled.p`
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
`;

const Divider = styled.hr`
  color: white;
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
`;

const HostSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const HostImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
`;

const HostInfo = styled.div`
  color: white;
  font-size: 14px;
`;

const FeatureSection = styled.div`
  margin-bottom: 20px;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const FeatureIcon = styled.img`
  color: white;
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const FeatureText = styled.p`
  font-size: 14px;
  color: white;
`;

const Description = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  color: white;
`;

const GuestSelector = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
`;

const TotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
`;

const ReserveButton = styled.a`
  background-color: #ff385c;
  color: white;
  border: none;
  padding: 10px 20px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e3335f; /* Slightly darker shade for hover effect */
  }
`;

const SmallText = styled.p`
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  color: #717171;
`;

const ServiceList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
`;

const ServiceItem = styled.div`
  color: white;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: white;
`;

const ServiceIcon = styled.span`
  font-size: 20px;
  margin-right: 8px;
  color: white;
`;
const Section = styled.div`
  margin-bottom: 20px;
`;

const Button = styled.a`
  display: inline-block;
  margin-top: 10px; /* Alinea el botón al final de la sección */
  padding: 10px 20px;
  background-color: #f4ac1c;
  color: #fff;
  text-decoration: none; /* Evita el subrayado */
  text-align: center;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    background-color: #d69317; /* Color de fondo al hacer hover */
  }
`;
// Estilos existentes
const Table = styled.table`
  border-collapse: collapse;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  text-align: center;
  overflow-x: auto; /* Permite el desplazamiento horizontal en pantallas pequeñas */
  display: block; /* Hace que la tabla se comporte como un bloque para el desplazamiento horizontal */
`;

const Th = styled.th`
  background-color: #f4ac1c;
  color: white;
  padding: 8px;
  font-size: 14px; /* Tamaño de fuente ajustado */
`;

const Td = styled.td`
  border: 1px solid #dddddd;
  padding: 8px;
  font-size: 14px; /* Tamaño de fuente ajustado */
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;
const ElegantSection = styled.section`
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;
const List = styled.ul`
  list-style-type: disc;
  margin: 10px 0;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
`;
const Row = styled.div`
  display: flex;
  align-items: flex-start; /* Alinea los elementos al inicio del contenedor */
  gap: 20px; /* Espacio entre la imagen y el contenido */
  padding: 20px;
`;

const LeftSide = styled.div`
  flex: 1;
  max-width: 300px; /* Ajusta el tamaño máximo de la imagen */
`;

const RightSide = styled.div`
  flex: 2;
`;

// Configuración del icono del marcador
const customIcon = new L.Icon({
  iconUrl: posiciom,
  iconSize: [38, 38],
  iconAnchor: [22, 38],
  popupAnchor: [-3, -76],
});

// Estilos para el contenedor del mapa
const MapWrapper = styled.div`
  width: 50%;
  height: 400px; /* Ajusta la altura según tus necesidades */
  margin-bottom: 10px;
`;
function Cocina() {
  const phoneNumber = "+593997924630";

  const handleClick = () => {
    console.log("Link clicked");
  };
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const position = [-0.3525835274196284, -78.44864845917898]; // Coordenadas del centro del mapa
  // Función para abrir Google Maps con la dirección
  const handleDirectionsClick = () => {
    const destination = `${position[0]},${position[1]}`;
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${destination}`,
      "_blank"
    );
  };
  return (
    <Container>
      <ContainerImagen>
        <Title>CASA D´BRUNO - COCINA</Title>
        <GridContainer>
          <MainImage src={foto1} alt="Vista al mar" />
          <SmallImageGrid>
            <Image src={foto2} alt="Cocina 1" />
            <Image src={foto3} alt="Cocina 2" />
            <Image src={foto4} alt="Cocina 3" />
            <Image src={foto5} alt="Cocina 4" />
          </SmallImageGrid>
        </GridContainer>
        <ButtonContainer>
          <StyledLink to="/collageCocina" onClick={handleClick}>
            Mostrar todas las fotos
          </StyledLink>
        </ButtonContainer>
      </ContainerImagen>
      <ContainerCard>
        <ListingDetails>
          <Title>Restaurante a puertas cerradas en Casa D' Bruno:</Title>
          <Subtitle>
            Brunch temáticos · Veladas temáticas · Cocina Colaborativa
          </Subtitle>
          <Divider />
          <HostSection>
            <HostImage src={cocina} alt="Host" />
            <HostInfo>
              <p>Chef: Invitado por parte de CASA D´BRUNO </p>
            </HostInfo>
          </HostSection>
          <FeatureSection>
            <Feature>
              <FeatureIcon src={calendarios} alt="Icon" />
              <FeatureText>Ideal para Eventos - Reuniones</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon src={personas} alt="Icon" />
              <FeatureText>Capacidad Hasta 100 personas</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon src={calidad} alt="Icon" />
              <FeatureText>
                Cuenta con · Brunch temáticos · Veladas temáticas · Cocina
                Colaborativa
              </FeatureText>
            </Feature>
          </FeatureSection>
          <Divider />
          <Description>
            La cocina de CASA D´ BRUNO, cuenta con Chef y cocineros que
            presentarán un enfoque culinario que se centra en un tema
            específico, ya sea cultural o regional. Este estilo de cocina no
            solo se refleja en los platos servidos, sino también en la
            presentación, el ambiente y la experiencia general del comedor.
          </Description>
          <Divider />
          <Section>
            <Subtitle>Brunch Temáticos</Subtitle>
            <Description>
              No es un desayuno, tampoco es un almuerzo, sino prácticamente una
              fusión de las dos.
            </Description>
            <Description>
              <strong style={{ color: "white" }}>Horario:</strong>{" "}
              <b style={{ color: "white" }}>
                {" "}
                Fines de semana de 10:00 am a 14:00 pm.
              </b>
            </Description>
          </Section>
          <Section>
            <Subtitle>Veladas Temáticas</Subtitle>
            <Description>
              Son cenas temáticas que buscan ofrecer una experiencia culinaria
              completa y envolvente que va más allá de simplemente comer,
              proporcionando una inmersión en un tema específico que abarca
              todos los aspectos del entorno gastronómico.
            </Description>
            <Description>
              <strong style={{ color: "white" }}>Horario:</strong>{" "}
              <b style={{ color: "white" }}>
                Viernes y sábados de 20:00 pm a 1:00 am.
              </b>
            </Description>
            <Description>
              Estos eventos se promocionarán en redes sociales de Casa d´ Bruno,
              y su asistencia será bajo reservación, con lo cual avalamos total
              exclusividad para un grupo de personas y garantizamos que su
              experiencia sea única.
            </Description>
          </Section>
          <Divider />
          <ElegantSection>
            <Subtitle>Cocina Colaborativa</Subtitle>
            <Description>
              Casa d´ Bruno también presenta el concepto de la "cocina
              colaborativa", que utiliza su espacio como una fórmula para
              generar ingresos y valor en la comunidad local a través de
              alianzas con:
            </Description>
            <List>
              <ListItem>Chefs independientes.</ListItem>
              <ListItem>Estudiantes de gastronomía.</ListItem>
              <ListItem>Cocineros aficionados.</ListItem>
              <ListItem>Proveedores locales.</ListItem>
            </List>
            <Description>
              La idea es que cualquier emprendedor culinario que quiera explotar
              sus atributos y generar ingresos, puede acercarse a nuestra cocina
              y aprovechar nuestro espacio ya instalado, utilizándolo bajo las
              modalidades establecidas por Casa d´ Bruno.
            </Description>
            <ServiceList>
              <ServiceItem>
                <ServiceIcon>💡</ServiceIcon>
                Buena iluminación
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🏕️</ServiceIcon>
                Acceso a zonas verdes
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🚗</ServiceIcon>
                Aparcamiento gratuito
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🍳</ServiceIcon>
                Cocina
              </ServiceItem>
              <ServiceItem style={{ textDecoration: "line-through" }}>
                <ServiceIcon>🚨</ServiceIcon>
                Seguridad
              </ServiceItem>
              <ServiceItem>
                <ServiceIcon>🔥</ServiceIcon>
                Parilla
              </ServiceItem>
            </ServiceList>
            <ReserveButton
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                "Mas informacion sobre Cocina colaborativa"
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Reservar
            </ReserveButton>
            <SmallText>Contactate con nosotros</SmallText>
          </ElegantSection>
          <Divider />
        </ListingDetails>
        <ImageEventos src={cocina} alt="Cocina 1" />
      </ContainerCard>
      {/* Agregar el mapa */}
      <Title>Nuestra ubicacion Casa D' Bruno</Title>
      <MapWrapper>
        <MapContainer
          center={position}
          zoom={15}
          scrollWheelZoom={false}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} icon={customIcon}>
            <Popup>
              Aquí es donde te quedarás.
              <br />
              <button onClick={handleDirectionsClick}>Cómo llegar</button>
            </Popup>
          </Marker>
        </MapContainer>
      </MapWrapper>
      <Button onClick={handleDirectionsClick}>Cómo llegar</Button>
    </Container>
  );
}

export default Cocina;
