import React from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import styled from "styled-components";
import { experiences } from "../../data/constants";
import ExperienceCard from "../cards/ExperienceCard";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-contnet: center;
  margin-top: 50px;
  position: rlative;
  z-index: 1;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const Title = styled.div`
  font-size: 52px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;
const Desc = styled.div`
  font-size: 18px;
  text-align: justify;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    font-size: 17px;
  }

  @media (max-width: 992px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    margin-bottom: 30px;
  }

  @media (max-width: 576px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

const Experience = () => {
  return (
    <Container id="Experience">
      <Wrapper>
        <Title>CASA D´ BRUNO</Title>
        <Desc
          style={{
            marginBottom: "40px",
          }}
        >
          Casa d´ Bruno nace como un emprendimiento que se inspira en la
          economía colaborativa como modelo de negocio, en el que se oferta el
          alquiler de espacios, bienes y servicios de uso limitado por sus
          dueños y que son requeridos por otras personas en forma temporal. La
          idea central es maximizar la utilización de recursos existentes y
          ofertar una alternativa económica y flexible al alquiler tradicional,
          Además, fomenta una economía más inclusiva al permitir que más
          personas puedan acceder a bienes y servicios a precios asequibles.
          Esto se promoverá mediante redes sociales que irán creando la
          comunidad que visite Casa D´ Bruno.
        </Desc>

        <VerticalTimeline>
          {experiences.map((experience, index) => (
            <ExperienceCard
              key={`experience-${index}`}
              experience={experience}
            />
          ))}
        </VerticalTimeline>
      </Wrapper>
    </Container>
  );
};

export default Experience;
